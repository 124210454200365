import cx from 'classnames';
import React from 'react';

import {
  Box,
  Button, Card, CardActions, CardContent, Grid, makeStyles, Typography,
} from '@material-ui/core';

import { numberWithCommas } from 'utils/lib';

export type SimplePricingPlan = {
  name: string;
  numApns: number;
  price: number; // 100 = $1
};

const plans: SimplePricingPlan[] = [
  {
    name: 'Starter Farm',
    numApns: 500,
    price: 67900,
  },
  {
    name: 'Farm Pro',
    numApns: 1000,
    price: 119900,
  },
  {
    name: 'Full Harvist',
    numApns: 1500,
    price: 165000,
  },
];

const useStyles = makeStyles((theme) => ({
  card: {
    height: '365px',
    display: 'flex',
    flexDirection: 'column',
  },
  cardSelected: {
    borderColor: theme.palette.primary.main,
    borderWidth: '3px',
    borderStyle: 'solid',
  },
  cardContent: {
    flexGrow: 1,
    textAlign: 'center',
  },
  cardActions: {
    justifyContent: 'center',
  },
  touchesGrid: {
    fontSize: '12px',
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      textAlign: 'left',
    },
  },
  greenSquare: {
    marginRight: '8px',
    display: 'inline-block',
    width: 10,
    height: 10,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 0,
  },
}));

export type PricingCardProps = {
  plan: SimplePricingPlan;
  selected: boolean;
  onSelect: (plan: SimplePricingPlan) => void;
};

const GreenSquare = () => {
  const classes = useStyles();

  return (
    <Box className={classes.greenSquare} />
  );
};

const PricingCard = ({ plan, selected, onSelect }: PricingCardProps) => {
  const classes = useStyles();

  return (
    <Card className={cx(classes.card, selected && classes.cardSelected)}>
      <CardContent className={classes.cardContent}>
        <Typography color="primary" gutterBottom style={{ fontWeight: 600, fontSize: '20px' }}>
          {plan.name}
        </Typography>
        <Typography color="secondary" gutterBottom>
          <span style={{ fontSize: '28px', fontWeight: 600 }}>{`$${numberWithCommas(plan.price / 100)}`}</span>
          <span style={{ fontSize: '14px', fontWeight: 500, marginLeft: '10px' }}>/ mo</span>
        </Typography>
        <Typography style={{ fontWeight: 700, fontSize: '18px' }}>
          {numberWithCommas(plan.numApns)}
        </Typography>
        <Typography style={{ fontWeight: 600, fontSize: '16px', marginBottom: '18px' }}>
          Exclusive Homes
        </Typography>
        <Typography variant="body2" style={{ marginBottom: '18px' }}>
          One print and one digital touch per month
        </Typography>
        <Grid container spacing={2} className={classes.touchesGrid}>
          <Grid item xs={12} sm={6}>
            <GreenSquare />
            8 Postcards
          </Grid>
          <Grid item xs={12} sm={6}>
            <GreenSquare />
            4 Letters
          </Grid>
          <Grid item xs={12} sm={6}>
            <GreenSquare />
            8 Emails
          </Grid>
          <Grid item xs={12} sm={6}>
            <GreenSquare />
            4 Voicemails
          </Grid>
        </Grid>
      </CardContent>
      <CardActions className={classes.cardActions}>
        <Button
          variant="contained"
          color={selected ? 'primary' : 'secondary'}
          onClick={() => onSelect(plan)}
        >
          {selected ? 'Selected' : 'Select'}
        </Button>
      </CardActions>
    </Card>
  );
};

type SimplePricingCardsProps = {
  handleSelectPlan: (plan: SimplePricingPlan) => void;
};

export default ({ handleSelectPlan }: SimplePricingCardsProps) => {
  const [selectedCard, setSelectedCard] = React.useState<number | null>(null);

  return (
    <Grid container spacing={4} justifyContent="center">
      {plans.map((plan, i) => (
        <Grid item xs={12} sm={6} md={4} key={i}>
          <PricingCard
            plan={plan}
            selected={selectedCard === i}
            onSelect={() => { setSelectedCard(i); handleSelectPlan(plan); }}
          />
        </Grid>
      ))}
    </Grid>
  );
};
