import React from 'react';

import {
  Step, StepContent, StepLabel, Stepper, Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { useAuthDataContext } from 'components/AuthDataProvider/AuthDataProvider';
import { useDataFilterContext } from 'components/DataMap/DataFilterProvider';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import SimpleCreateAccountStep from 'components/SimpleCampaign/SimpleCreateAccountStep';
import SimpleCreateCampaignStep from 'components/SimpleCampaign/SimpleCreateCampaignStep';
import { SimplePricingPlan } from 'components/SimpleCampaign/SimplePricingCards';
import { useUserInfoContext } from 'components/UserInfoProvider/UserInfoProvider';

import analyticsClient from 'utils/analytics';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '0 auto',
    padding: '24px 30px 12px',
    position: 'relative',

    [theme.breakpoints.down('sm')]: {
      padding: '50px 30px 12px',
    },

    '@media (min-width: 600px)': {
      width: '580px', // 12 34px-width columns with 14px gutters
    },
    '@media (min-width: 900px)': {
      width: '840px', // 12 46px-width columns with 24px gutters
    },
    '@media (min-width: 1200px)': {
      width: '1104px', // 12 60px-width columns with 32px gutters
    },
    '@media (min-width: 1440px)': {
      width: '1344px', // 12 80px-width columns with 32px gutters (including on ends, so 12 * 80 + 12 * 32)
    },
  },
  stepLabel: {
    fontSize: '18px',
  },
  stepDescription: {
    marginBottom: '20px',
  },
  stepContainer: {

  },
  map: {
    minHeight: '95vh',
    display: 'flex',
    flexDirection: 'column',
    margin: '0 0 20px',
  },
  startButton: {
    float: 'right',
  },
}));

const steps: { [key: string]: string } = {
  'Choose farm': '',
  'Create account': 'Get started by entering basic information about yourself to create your Harvist account',
};

const SimpleCreateCampaignPage = () => {
  const classes = useStyles();
  const {
    availableEventTypes, selectedEventType, setSelectedEventTypeByName,
  } = useDataFilterContext() as any;
  const { onLogout } = useAuthDataContext() as any;
  const { reloadUserInfo } = useUserInfoContext();

  const [activeStep, setActiveStep] = React.useState(0);

  const [selectedPlan, setSelectedPlan] = React.useState<SimplePricingPlan | undefined>();

  const [zipCode, setZipCode] = React.useState<string | undefined>();
  const [apnsTotal, setApnsTotal] = React.useState<number | undefined>();
  const [apnsWithPropensityToSell, setApnsWithPropensityToSell] = React.useState<number | undefined>();

  // TODO: TEMP
  // const [zipCode, setZipCode] = React.useState<string | undefined>('91202');
  // const [apnsTotal, setApnsTotal] = React.useState<number | undefined>(4560);
  // const [apnsWithPropensityToSell, setApnsWithPropensityToSell] = React.useState<number | undefined>(123480);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  React.useEffect(() => {
    if (!availableEventTypes) return;

    // Always full farm
    setSelectedEventTypeByName('Full Farm');
    const analyticsCampaignEventType = 'Full Farm';

    // Track GTM event
    analyticsClient.track('simple_campaign_creation_started', {
      campaign_event_type: analyticsCampaignEventType,
    });
  }, [availableEventTypes]);

  React.useEffect(() => {
    // Force a logout as a sanity check in case a logged in user comes to this page
    // We do this after selecting a plan so that salespeople can use the map functionality while logged in
    if (selectedPlan) {
      (async () => {
        onLogout();
        await new Promise((resolve) => setTimeout(resolve, 300)); // Sanity check to make sure cookies clear
        reloadUserInfo();
      })();
    }
  }, [selectedPlan]);

  if (!selectedEventType) return (<LoadingIndicator modal />);

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {Object.keys(steps).map((label) => (
          <Step key={label}>
            <StepLabel classes={{ label: classes.stepLabel }}>{label}</StepLabel>
            <StepContent>
              <Typography className={classes.stepDescription}>{steps[label]}</Typography>
              <div className={classes.stepContainer}>
                {activeStep === 0 && (
                  <SimpleCreateCampaignStep
                    handleNext={handleNext}
                    setZipCode={setZipCode}
                    apnsTotal={apnsTotal}
                    setApnsTotal={setApnsTotal}
                    apnsWithPropensityToSell={apnsWithPropensityToSell}
                    setApnsWithPropensityToSell={setApnsWithPropensityToSell}
                    selectedPlan={selectedPlan}
                    setSelectedPlan={setSelectedPlan}
                  />
                )}

                {activeStep === 1 && (
                  <SimpleCreateAccountStep
                    zipCode={zipCode!}
                    selectedPlan={selectedPlan!}
                  />
                )}
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

export default SimpleCreateCampaignPage;
